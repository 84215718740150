import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'

import pic01 from '../images/pic01.jpg'
import contact_img from '../images/contact_img.jpg'
import blog_img from '../images/blog_img.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <p>
            Welcome to the AitopiA Initiative page, great that you have found
            us, and great to have you here!
          </p>
          <p>
            We, Natasha and Mark, have started this initiative because we are
            both involved in the AI ecosystem and because we care about our
            planet and our human society.
          </p>
          <p>
            AI offers tremendous possibilities to improve our lives. It can
            automate away the boring stuff, it can make sure that security works
            in real-time, and it can help doctors detect diseases. However, this
            power comes has to come with great responsibility. AI is a powerful
            tool, and we need to make sure that it is used for the betterment of
            society.
          </p>
          <p>
            Current AI models (and the engineers creating the models) have a
            range of challenges laid out for them when it comes to
            responsibility, ethics, and sustainability. Think about predictive
            models that discriminate on gender, age, and race and the amount of
            power that these models hold over society (frequently without the
            society knowing about it).
          </p>
          <p>
            Moreover, for the past years, most of the trends within AI research
            are directed towards marginal improvements in model performance,
            which results in more and more power-hungry algorithms. As we saw
            somewhere on the Internet:
          </p>
          <p style={{ fontStyle: 'italic' }}>
            "Your machine learning model might be melting icebergs!"
          </p>
          <p>
            We believe that much more attention has to be given to the power
            consumption and efficiency of the models and that the (lack of)
            environmental impact has to be one of the driving metrics in
            developing new state-of-the-art AI algorithms.
          </p>
          <p>
            This is where AitopiA comes in: We want to create a platform where
            we can share information on and discuss AI that is responsible,
            sustainable, and ethical. This is not only a challenge for the
            industry, but also for the AI community. We want to help to make
            this happen.
          </p>
          {close}
        </article>

        <article
          id="videos"
          className={`${this.props.article === 'videos' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Videos</h2>
          <p>
            On this page, we will place recordings of our previous events such
            as webinars and meetups. Also, we might highlight some videos on
            responsible and sustainable AI which we really find insightful!
          </p>
          <h2 className="major">Webinars & Meetups</h2>
          <p>
            The first webinar we organized on the topic of Sustainable and
            Climate AI was in collaboration with Xomnia. To kick things off, we
            invited Angela Fan and a duo of Peter van de Putten and Jeroen van
            der Most.
          </p>
          <p>
            Angela Fan talked about the technical side of things: how can we
            create more sustainable AI models without sacrificing too much.
            Angela is a PhD student at INRIA Nancy and Facebook AI Research
            Paris and is an active contributor and thinker in the field, as she
            recently co-organised the SustaiNLP workshop at the EMNLP 2020
            conference.
          </p>
          <p>
            Following Angela’s talk, Peter van de Putten and Jeroen van der Most
            showed us a more artistic way to approach the problem and raise
            awareness around climate change by letting the AI speak for itself
            by generating text through an AI model.
          </p>
          <div>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=oOfIl_m4pXc"
              controls="true"
            />
          </div>
          <hr></hr>
          <p>
            The second webinar we organized on the topic of Sustainable and
            Climate AI was also in collaboration with Xomnia. In this edition,
            we invited Lucia Loher, the Product Lead at
            <a href="https://jina.ai"> Jina AI</a> and Ricardo Vinuesa, who is
            an Associate Professor and Affiliated Researcher in the AI
            Sustainability Center at KTH Royal Institute of Technology.
          </p>
          <p>
            Why does Jina AI contribute to the sustainable AI goal? In our
            opinion, democratization of AI is an important step in making the
            usage more sustainable. By sharing the pre-trained models, and
            making the existing algorithms as efficient as possible
            out-of-the-box, it becomes less necessary for each individual
            developer to train and fine-tune their own models!
          </p>
          <p>
            As for Ricardo's work, his paper on the role of artificial
            intelligence in achieving the UN Sustainable Development Goals was
            published in Nature Communications Journal and inspires great
            conversations on the idea that AI can both enable and inhibit the
            accomplishment of the set sustainable development goals.
          </p>
          <div>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=yTFN0pBaz7M"
              controls="true"
            />
          </div>
          {close}
        </article>

        <article
          id="blog"
          className={`${this.props.article === 'blog' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Blog</h2>
          <span className="image main">
            <img src={blog_img} alt="" />
          </span>
          <p>Coming soon!</p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <span className="image main">
            <img src={contact_img} alt="" />
          </span>
          <p>Coming soon!</p>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main

import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <p className="linkedin_headsup">Follow our LinkedIn page:</p>
    <a
      style={{ display: 'inline-block' }}
      href="https://www.linkedin.com/company/aitopia-initiative"
    >
      <p className="linkedin_title_aitopia_page">Aitopia Initiative</p>
      <p
        className="icon fa-linkedin linkedin_logo"
        style={{ display: 'inline' }}
      ></p>
    </a>
    <p className="linkedin_headsup" style={{ paddingTop: '50px' }}>
      And reach out to us personally:
    </p>
    <div style={{ paddingBottom: '10px' }}>
      <a
        style={{ display: 'inline-block' }}
        href="https://www.linkedin.com/in/natashashroff/"
      >
        <p className="linkedin_title">Natasha Shroff</p>
        <p
          className="icon fa-linkedin linkedin_logo"
          style={{ display: 'inline' }}
        ></p>
      </a>
    </div>
    <div>
      <a
        href="https://www.linkedin.com/in/markberger-1"
        style={{ display: 'inline-block' }}
      >
        <p className="linkedin_title" style={{ color: 'khaki' }}>
          Mark Berger
        </p>
        <p
          className="icon fa-linkedin linkedin_logo"
          style={{ display: 'inline' }}
        ></p>
      </a>
    </div>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
